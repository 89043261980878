.mde-header {
  background: transparent;
}

.react-mde .invisible {
  display: none;
}

.mde-header {
  border: 0;
  align-items: center;
}

.mde-header .mde-tabs {
  display: inline-flex;
  background-color: #000;
  background-color: rgba(0,0,0,1);
  padding: .25rem;
}

.mde-header .mde-tabs button {
  padding: .25rem 0.25rem;
  font-family: Bebas Neue,Helvetica,Arial,sans-serif;
  font-weight: 400;
  font-size: 1.1rem;
  --text-opacity: 1;
  color: #fff;
  color: rgba(255,255,255,var(--text-opacity));
  text-align: center;
  cursor: pointer;
  border: 0;
  border-radius: 0;
  margin: 0 !important;
  outline: 0;
}

.mde-header {
  /* grey-50 */
  background-color: #f7f7f7;
}

.mde-header .mde-tabs button.selected {
  /* blue-300 */
  background: #027da8;
  color: #fff;
  border: 0;
  border-radius: 0;
  margin: 0;
  outline: 0;
}

.mde-header .mde-tabs button:not(.selected):hover {
  /* grey-500 */
  background: #303132;
}

.mde-header .mde-header-item {
  border: 1px transparent solid;
  transition: border-color 100ms ease-in-out;
}

.mde-header ul.mde-header-group li.mde-header-item {
  margin: 0;
}

.mde-header .mde-header-item:hover {
  /* grey-200 */
  border: 1px #adadad solid;
}

.mde-text {
  font-family: monospace;
}


.mde-header ul.mde-header-group {
  padding: 0;
}

.mde-header ul.mde-header-group:first-of-type {
  padding-left: .5rem;
}

.mde-header ul.mde-header-group + .mde-header-group {
  margin-left: 0;
}

@media (min-width: 992px) {
  .mde-header .mde-tabs button {
    padding: .5rem 1rem;
  }
}

@media (min-width: 1200px) {
  .mde-header ul.mde-header-group {
    padding: 0 0.5rem;
  }

  .mde-header ul.mde-header-group + .mde-header-group {
    margin-left: .5rem;
  }
}
